/* Blog card styling */
.blog-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.blog-card-link {
  text-decoration: none;
  color: inherit;
  height: 100%;
}

.blog-card-image {
  height: 200px;
  overflow: hidden;
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-card-image img {
  transform: scale(1.05);
}

.blog-card-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.blog-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #1a202c;
}

.blog-card-date {
  font-size: 0.875rem;
  color: #718096;
  margin-bottom: 1rem;
  font-style: italic;
}

.blog-card-readLength {
  font-size: 0.875rem;
  color: #242930;
  margin-bottom: 1rem;
}

.blog-card-summary {
  font-size: 1rem;
  color: #4a5568;
  line-height: 1.5;
}

/* Blog post styling */
.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.blog-post-header {
  margin-bottom: 2rem;
}

.blog-post-title {
  font-size: 2.25rem;
  font-weight: 700;
  color: #1a202c;
  margin-bottom: 0.5rem;
}

.blog-post-date {
  font-size: 1rem;
  color: #718096;
  font-style: italic;
}

.blog-post-content {
  font-size: 1.125rem;
  line-height: 1.7;
  color: #2d3748;
}

.blog-post-content h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #1a202c;
}

.blog-post-content p {
  margin-bottom: 1.5rem;
}

.blog-post-content pre {
  background-color: #f7fafc;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-x: auto;
  margin-bottom: 1.5rem;
}

.blog-post-content code {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.blog-post-content ul, .blog-post-content ol {
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

.blog-post-content li {
  margin-bottom: 0.5rem;
}

.blog-post-content img {
  max-width: 100%;
  height: auto;
  margin: 1.5rem 0;
  border-radius: 0.5rem;
}

.blog-post-content a {
  color: #3182ce;
  text-decoration: none;
}

.blog-post-content a:hover {
  text-decoration: underline;
}

.blog-back-button {
  display: inline-block;
  margin-bottom: 1.5rem;
  color: #4a5568;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.2s ease;
}

.blog-back-button:hover {
  color: #1a202c;
}

/* Blog page styling */
.blog-page {
  min-height: 100vh;
  background-color: #f7fafc;
}

.blog-page-header {
  text-align: center;
  margin-bottom: 3rem;
}

.blog-page-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #162135;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 640px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .blog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
