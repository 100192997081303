@media (max-width: 768px) {
    .mobile-hidden {
        display: none;
    }
    
    .optimized-mobile {
        /* Adjust the layout for smaller screens */
        text-align: center;
    
        /* Center and resize images */
        img {
          position: relative; /* Change from absolute to relative */
          width: 50%; /* Smaller width for images */
          height: auto; /* Adjust height automatically */
          left: 0; /* Reset left position */
          bottom: 0; /* Reset bottom position */
          opacity: 1; /* Adjust opacity if needed */
        }
    
        /* Adjust content */
        .container {
          flex-direction: column;
          padding: 20px; /* Add padding for smaller screens */
        }
    
        /* Resize text */
        h1.text-7xl {
          font-size: 3rem; /* Smaller font size */
        }
    
        p.text-xl {
          font-size: 1rem; /* Smaller font size */
        }
      }

      .optimized-mobile-2 {
        /* Width The size of the screen */
        width: 100%;
      }

      .optimized-mobile-3 {
        padding-top: 20px;
      }
    
    /* Mobile menu styling */
    nav button.computer-hidden {
      display: block;
      cursor: pointer;
    }
    
    /* Mobile dropdown menu */
    nav .fixed {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transition: all 0.3s ease;
    }
    
    /* Project cards for mobile */
    #projects .flex-wrap > div {
      width: 100%;
    }
    
    /* Animations for mobile menu */
    @keyframes slideDown {
      from { opacity: 0; transform: translateY(-10px); }
      to { opacity: 1; transform: translateY(0); }
    }
    
    nav .fixed {
      animation: slideDown 0.3s ease forwards;
    }
}

/* Normal laptop screen */
@media (min-width: 768px) {
    .computer-hidden {
        display: none;
    }
}
