@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.jobContainer {
  display: flex;
  background-color: #f3f4f6; /* Light grey background */
  border-radius: 0.5rem; /* Rounded corners */
  overflow: hidden; /* Ensures the child elements adhere to the container's border radius */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1); /* Shadow effect */
  margin-bottom: 1rem; /* Space between job entries */
}

.jobImage {
  flex-basis: 30%; /* Image takes up 30% of the container width */
  background-color: #64748b; /* Placeholder color for the image */
  height: 150px; /* Set a fixed height for the image */
}

.jobDetails {
  flex-basis: 70%; /* Text details take up the remaining 70% */
  padding: 1rem; /* Padding inside the text details */
}

.jobTitle {
  font-size: 1.25rem; /* Larger text for the job title */
  font-weight: bold; /* Make the title bold */
  margin-bottom: 0.5rem; /* Space below the title */
}

.jobInfo {
  margin-bottom: 1rem; /* Space between job info and description */
}

/* Use media queries to adjust the layout on smaller screens */
@media (max-width: 768px) {
  .jobContainer {
    flex-direction: column;
  }

  .jobImage {
    flex-basis: auto; /* Image takes the full width on small screens */
    height: auto; /* Adjust height to maintain aspect ratio */
  }

  .jobDetails {
    flex-basis: auto; /* Details take the full width on small screens */
  }

  .svg-container {
    width: 100%;
  }
}

#typing {
  white-space: nowrap;
  overflow: hidden;
}

.blink {
  border-right: 2px solid black;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

.footer {
  background-image: url('./images/hero/headerGradient.crop.webp'); /* Path to your image */
  background-size: cover;
  background-position: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-links {
  display: flex;
  gap: 20px; /* Adjust the gap as needed */
}

.footer-links a {
  color: white; /* White text for links */
  text-decoration: none;
}

/* Styles for GitHub icon */
.github-icon:hover, .github-icon:focus {
  color: #333; /* GitHub hover color */
}

/* Styles for LinkedIn icon */
.linkedin-icon:hover, .linkedin-icon:focus {
  color: #0077B5; /* LinkedIn hover color */
}

/* Smooth color transition for icons */
.footer-links a svg {
  transition: color 0.3s;
}

/* Additional media query for responsiveness if needed */
@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    gap: 20px;
  }
}